import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import {
  faPiggyBank,
  faTachometerAlt,
  faServer,
  faGlobeAmericas,
  faCalculator,
  faFileImport,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const FeaturesPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title="Features" />
      <article className="article text--center">
        <header className="article-header">
          <h1 className="heading heading--md">{t("features.header")}</h1>
          <p className="paragraph paragraph--lg">
            <Trans>features.header-paragraph</Trans>
          </p>
        </header>

        <ul className="features-list">
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faPiggyBank} />
            </div>
            <h2>
              <Trans>features.item-header-1</Trans>
            </h2>
            <p>
              <Trans>features.item-paragraph-1</Trans>
            </p>
          </li>

          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faTachometerAlt} />
            </div>
            <h2>
              <Trans>features.item-header-2</Trans>
            </h2>
            <p>
              <Trans>features.item-paragraph-2</Trans>
            </p>
          </li>

          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faServer} />
            </div>
            <h2>
              <Trans>features.item-header-3</Trans>
            </h2>
            <p>
              <Trans>features.item-paragraph-3</Trans>
            </p>
          </li>

          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faGlobeAmericas} />
            </div>
            <h2>
              <Trans>features.item-header-4</Trans>
            </h2>
            <p>
              <Trans>features.item-paragraph-4</Trans>
            </p>
          </li>

          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faCalculator} />
            </div>
            <h2>
              <Trans>features.item-header-5</Trans>
            </h2>
            <p>
              <Trans>features.item-paragraph-5</Trans>
            </p>
          </li>

          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faFileImport} />
            </div>
            <h2>
              <Trans>features.item-header-6</Trans>
            </h2>
            <p>
              <Trans>features.item-paragraph-6</Trans>
            </p>
          </li>
        </ul>
      </article>
    </Layout>
  )
}
export default FeaturesPage
